import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'statistics_account_period',
    primary_key: 'transactionable_id'
  },
).store()

export default resource
