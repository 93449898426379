import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'orders',
    prefix_state: 'order_payment_orders',
    prefix: 'order.payment_orders',
  },
  {
    default_query: {
      include: 'type,status,customer',
      append: 'lading_bills',
    }
  }
).store()

export default resource
