export default [
  {
    _name: "TSidebarNavItem",
    name: "Dashboard",
    to: "/accounting/dashboard",
    icon: "cil-speedometer",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Accounting",
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Cash fund',
    route: "/accounting/fund",
    icon: "cil-donate",
    items: [
      {
        name: "Collect money",
        to: "/accounting/fund/collect-money",
      },
      {
        name: "Spend money",
        to: "/accounting/fund/spend-money",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Bank',
    icon: "cil-bank",
    route: "/accounting/bank",
    items: [
      {
        name: "Collect money",
        to: "/accounting/bank/collect-money",
      },
      {
        name: "Spend money",
        to: "/accounting/bank/spend-money",
      },
      {
        name: "Internal transfer",
        to: "/accounting/bank/internal-transfer",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Purchase',
    route: "/accounting/purchase",
    icon: "cil-cart-arrow-down",
    items: [
      {
        name: "Supplier debt",
        to: "/accounting/purchase/supplier-debt",
      },
      {
        name: "Comparison clearing debt",
        to: "/accounting/purchase/comparison-clearing-debt",
      },
      {
        name: "Compensation",
        to: "/accounting/purchase/compensation",
      },
      {
        name: "Payment due date",
        to: "/accounting/purchase/payment-due-date",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Sale',
    route: "/accounting/sale",
    icon: "cil-cart",
    items: [
      {
        name: "Customer debt",
        to: "/accounting/sale/customer-debt",
      },
      {
        name: "Comparison clearing debt",
        to: "/accounting/sale/comparison-clearing-debt",
      },
      {
        name: "Compensation",
        to: "/accounting/sale/compensation",
      },
      {
        name: "Payment due date",
        to: "/accounting/sale/payment-due-date",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Warehouse',
    route: "/accounting/warehouse",
    icon: "cil-widgets",
    items: [
      {
        name: "Container costs",
        to: "/accounting/warehouse/container-costs",
      },
      // {
      //   name: "Spend money",
      //   to: "/accounting/warehouse/spend-money",
      // },
      {
        name: "Compensation",
        to: "/accounting/warehouse/compensation",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'General accounting',
    icon: "cil-folder-alt",
    route: "/accounting/general",
    items: [
      {
        name: "Orther receipt",
        to: "/accounting/general/orther-receipt",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Accounting inventory',
    icon: "cil-folder-plus",
    route: "/accounting/inventory",
    items: [
      {
        name: "Accounting inventory distribution",
        to: "/accounting/inventory/distribution",
      },
    ]
  },
  {
    _name: "TSidebarNavDropdown",
    name: 'Statistics',
    icon: "cil-chart-line",
    route: "/accounting/statistics",
    items: [
      {
        name: "Transaction statistics",
        to: "/accounting/statistics/transaction",
      },
      {
        name: "Accounting account",
        to: "/accounting/statistics/account",
      },
      {
        name: "Card statistics",
        to: "/accounting/statistics/card",
      },
    ]
  },
  {
    _name: "TSidebarNavTitle",
    content: "Account",
  },
  {
    _name: "TSidebarNavItem",
    name: "Customer wallet",
    to: "/accounting/account/customer",
    icon: "cil-wallet",
  },
  {
    _name: "TSidebarNavItem",
    name: "Supplier wallet",
    to: "/accounting/account/supplier",
    icon: "cil-wallet",
  },
  {
    _name: "TSidebarNavItem",
    name: "Credit card",
    to: "/accounting/account/credit",
    icon: "cis-credit-card",
  },
  {
    _name: "TSidebarNavItem",
    name: "Bank card",
    to: "/accounting/account/bank-card",
    icon: "cil-credit-card",
  },
  {
    _name: "TSidebarNavItem",
    name: 'Cash fund',
    to: "/accounting/account/funds",
    icon: "cil-donate",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Setting",
  },
  {
    _name: "TSidebarNavItem",
    name: "Bank & Currency",
    to: "/accounting/setting/banks",
    icon: "cil-bank",
  },
  {
    _name: "TSidebarNavTitle",
    content: "Support",
  },
  {
    _name: "TSidebarNavItem",
    name: "Support",
    to: "/accounting/supports",
    icon: "cil-comment-bubble-question",
  },
]
