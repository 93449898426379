import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'transactions_transfer',
  },
).store()

const PREFIX = "accounting.transactions_transfer";

const actions = {
  [PREFIX + ".same_currency_transfer"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.accounting.transactions_transfer
        .sameCurrencyTransfer(attributes)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".other_currency_transfer"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.accounting.transactions_transfer
        .otherCurrencyTransfer(attributes)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
