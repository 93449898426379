import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'purchases',
    prefix: 'order.purchase_payable',
    prefix_state: 'purchase_payable',
  },
  {
    default_query: {
      include: "supplier,buyer",
      'filter[outstanding]': 1,
      sort: "-created_at",
    },
  },
).store()

export default resource
