import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'transactions_compensation_purchase',
  },
).store()

export default resource
