import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'statistics_cards_period',
    paginate: false,
  },
).store()

export default resource
