import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_compensations',
    prefix: 'order.sale_compensations',
    prefix_state: 'order_sale_compensations',
  },
  {
    default_query: {
      include: "order,order.customer",
      'filter[available_for_payment]': 1,
      sort: "-updated_at",
    },
  },
).store()

export default resource
