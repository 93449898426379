import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'transactions_payment_order_refund',
  },
).store()

export default resource
