import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'lading_bills',
    prefix: 'warehouse.lading_bills_receivable',
    prefix_state: 'warehouse_lading_bills_receivable',
  },
  {
    default_query: {
      include: "customer",
      'filter[outstanding]': 1,
      sort: "-updated_at",
    },
  },
).store()

export default resource
