import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'statistics_payment_due_date_suppliers',
    primary_key: "supplier_id"
  },
).store()

export default resource
