import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'containers',
    prefix: 'warehouse.containers_object',
    prefix_state: 'warehouse_containers_object',
  },
  {
    default_query: {
      sort: "-created_at",
    },
  }
).store()

export default resource
