import wallets_users from "./accounting/wallets_users"
import banks from "./accounting/banks"
import transactions_deposit_with_cash from "./accounting/transactions_deposit_with_cash"
import transactions_payment_order from "./accounting/transactions_payment_order"
import transactions_compensation from "./accounting/transactions_compensation"
import transactions_refund_supplier from "./accounting/transactions_refund_supplier"
import transactions_withdrawal from "./accounting/transactions_withdrawal"
import transactions_receive_transfer from "./accounting/transactions_receive_transfer"
import wallets_suppliers from "./accounting/wallets_suppliers"
import transactions_payment_order_refund from "./accounting/transactions_payment_order_refund"
import transactions_payment_service_refund from "./accounting/transactions_payment_service_refund"
import transactions_compensation_refund from "./accounting/transactions_compensation_refund"
import transactions_payment_service from "./accounting/transactions_payment_service"
import transactions_payment_supplier from "./accounting/transactions_payment_supplier"
import transactions_payment_purchase from "./accounting/transactions_payment_purchase"
import transaction_receipts from "./accounting/transaction_receipts"
import payment_orders from "./order/payment_orders"
import purchase_payable from "./order/purchase_payable"
import order_receivable from "./order/order_receivable"
import transactions_transfer from "./accounting/transactions_transfer"
import transactions_deposit_with_received from "./accounting/transactions_deposit_with_received"
import transactions_buy_foreign_currency from "./accounting/transactions_buy_foreign_currency"
import lading_bills_receivable from "./warehouse/lading_bills_receivable"
import funds from "./accounting/funds"
import transactions_payment_purchase_refund from "./accounting/transactions_payment_purchase_refund"
import accouting_lading_bill_compensations from "./warehouse/accouting_lading_bill_compensations"
import statistics_transaction_types from "./accounting/statistics_transaction_types"
import transactions_compensation_purchase from "./accounting/transactions_compensation_purchase"
import transactions_compensation_purchase_refund from "./accounting/transactions_compensation_purchase_refund"
import purchase_payment from "./order/purchase_payment"
import sale_compensations from "./order/sale_compensations"
import statistics_accounting_period from "./accounting/statistics_accounting_period"
import statistics_payment_due_date_suppliers from "./order/statistics_payment_due_date_suppliers"
import statistics_payment_due_date_users from "./order/statistics_payment_due_date_users"
import statistics_account_period from "./accounting/statistics_account_period"
import containers_object from "./warehouse/containers_object"
import statistics_cards_period from "./accounting/statistics_cards_period"

export default {
  wallets_users,
  banks,
  transactions_deposit_with_cash,
  transactions_payment_order,
  transactions_compensation,
  transactions_refund_supplier,
  wallets_suppliers,
  transactions_withdrawal,
  transactions_receive_transfer,
  transactions_payment_order_refund,
  transactions_payment_service_refund,
  transactions_compensation_refund,
  transactions_payment_service,
  transactions_payment_supplier,
  transactions_payment_purchase,
  transaction_receipts,
  payment_orders,
  purchase_payable,
  order_receivable,
  transactions_transfer,
  lading_bills_receivable,
  transactions_deposit_with_received,
  transactions_buy_foreign_currency,
  funds,
  transactions_payment_purchase_refund,
  accouting_lading_bill_compensations,
  statistics_transaction_types,
  transactions_compensation_purchase,
  transactions_compensation_purchase_refund,
  purchase_payment,
  sale_compensations,
  statistics_accounting_period,
  statistics_payment_due_date_suppliers,
  statistics_payment_due_date_users,
  statistics_account_period,
  containers_object,
  statistics_cards_period,
}
